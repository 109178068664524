export type MapMarker = {
	coordinates: MapCoordinates
	data?: any
}

export type MapCoordinates = {
	lat: number
	lng: number
}

export enum MarkerType {
	Main = 'MAIN',
	Default = 'DEFAULT',
}

export enum MarkerStyle {
	Main = 'MAIN',
	Default = 'DEFAULT',
	Active = 'ACTIVE',
	Selected = 'SELECTED',
}
