import mapboxgl from 'mapbox-gl'
import { MarkerStyle, MarkerType, type MapMarker, type MapCoordinates } from '@/types/Map'
import { TrackingMessages } from '@/constants/trackingMessages'
import { FeedbackId } from '@/constants/FeedbackId'
import { useFeedbackStore } from '@/store/feedback'

type MapBoxInitOptions = {
	containerId?: string
	initialZoom?: number
	center?: MapCoordinates
}

const markerIconClasses: { [key in MarkerStyle]: string } = {
	[MarkerStyle.Main]: 'w-8 h-8 bg-sky-400 border-sky-600',
	[MarkerStyle.Default]: 'bg-warm-grey-200 border-warm-grey-600',
	[MarkerStyle.Active]: 'bg-warm-grey-200 border-warm-grey-400',
	[MarkerStyle.Selected]: 'bg-white border-warm-grey-500 border-[6px]',
}

class MapBox {
	private instance: mapboxgl.Map | null = null

	public init(options: MapBoxInitOptions) {
		mapboxgl.accessToken = useRuntimeConfig().public.MAXBOXGL_TOKEN

		this.instance = new mapboxgl.Map({
			container: options?.containerId || 'map',
			style: 'mapbox://styles/mapbox/streets-v12',
			center: options?.center || { lat: 0, lng: 0 },
			zoom: options?.initialZoom || 10,
			attributionControl: false,
		}) as mapboxgl.Map

		this.instance.on('zoomstart', () => {
			utilTracking.track(TrackingMessages.COMPETITOR_MAP_ZOOM, {})
			useFeedbackStore().requestFeedback(FeedbackId.CompetitorsMapZoomInOrOut)
		})

		return this.instance
	}

	public getInstance() {
		return this.instance
	}

	public addMarker(coordinate: MapCoordinates, markerType: MarkerType, ref: HTMLElement) {
		if (!this.instance) {
			throw new Error('You need to init MapBox first')
		}

		const marker = new mapboxgl.Marker(ref).setLngLat([coordinate.lng, coordinate.lat])
		marker.addTo(this.instance)
		return marker
	}

	public addPopup(coordinate: MapCoordinates, ref: HTMLElement) {
		if (!this.instance) {
			throw new Error('You need to init MapBox first')
		}

		const popup = new mapboxgl.Popup({
			anchor: 'left',
			offset: 20,
		})
			.setLngLat([coordinate.lng, coordinate.lat])
			.setDOMContent(ref)

		return popup
	}

	public fitMap(markers: MapMarker[]) {
		const allCoordinates = markers.map((marker) => [marker.coordinates.lng, marker.coordinates.lat])
		const bounds = new mapboxgl.LngLatBounds()
		// @ts-ignore
		allCoordinates.forEach((coordinate) => coordinate && bounds.extend(coordinate))

		this.instance?.fitBounds(bounds, { center: [markers[0].coordinates.lng, markers[0].coordinates.lat] })
	}

	public zoomIn() {
		this.instance?.zoomIn()
	}

	public zoomOut() {
		this.instance?.zoomOut()
	}

	public resetInstance() {
		this.instance?.remove()
		this.instance = null
	}
}

export const mapBoxInstance = new MapBox()
